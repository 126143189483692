.spin {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -75px 0 0 0;
    border: 8px solid var(--bg-white);
    border-top: 8px solid var(--main-color);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

.spin-container.show {
    opacity: 1;
}

.spin-container.hide {
    opacity: 0;
    transition: opacity 0.2s ease-in;
    transition-delay: 0.2s;
}

@keyframes hide {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
