.textarea {
    width: 100%;
    resize: none;

    $color-track: #ffffff;
    $color-thumb: #c4c4c4;

    @media (min-width: 768px) {
        // Chrome, Edge, Safari, Opera
        &::-webkit-scrollbar {
            width: 9px;
            height: 9px;
        }
        &::-webkit-scrollbar-track {
            background: $color-track;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba($color-thumb, 0.5);
            border-radius: 6px;
            border: 2px solid $color-track;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgba($color-thumb, 0.8);
        }

        // Firefox
        & {
            scrollbar-width: thin;
            scrollbar-color: $color-thumb $color-track;
        }
    }
}

.modal-input {
    width: 100%;
    min-height: 48px;
    padding: 12px 8px 12px 8px;
    border: 1px solid var(--input-border-color);
    background: var(--input-bg-color);
    font-size: 1rem;
    line-height: 24px;
    border-radius: 6px;
    color: var(--wrapper-color-whit-black);
}
