.header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border: 1px solid var(--input-border-color);
    border-radius: 6px;
    background: var(--input-bg-color);
}

.selected-options {
    display: flex;
    overflow: hidden;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
    height: 32px;
}

.selected-option {
    display: flex;
    align-items: center;
    max-width: fit-content;
    height: 32px;
    padding: 8px;
    background: #d9ebf6;
    border-radius: 6px;
    white-space: nowrap;
    user-select: none;

    span {
        display: flex;
        align-items: center;
        margin-left: 5px;
        border-radius: 50%;
        cursor: pointer;

        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        svg {
            fill: var(--main-color);
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }
    }
}

.counter {
    margin-left: auto;
}
