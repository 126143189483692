.registry-check-modal {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .registry-check-error {
        &__errorlist {
            display: flex;
            flex-direction: column;
        }
    }
    .modal-input {
        margin-top: 1rem;
    }

    .btn {
        margin-top: 1rem;
    }

    .m05 {
        margin-top: 0.5rem;
    }

    .m2 {
        margin-top: 2rem;
    }

    .user-add-form,
    .registry-check-form {
        max-width: 500px;
    }

    .btn-secondary {
        width: 100%;
    }

    .registry-check-form {
        &__info span {
            padding-right: 5px;
        }
    }

    .registry-check-form {
        &__info {
            margin: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
        }

        .list-group-item {
            padding: 0.5rem;
        }

        .column {
            flex-flow: column;
        }
    }
}
.group-list-filter {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;

    &__item {
        display: flex;
        align-items: center;
        span {
            margin-right: 1rem;
        }
    }
}
