.stacked-inputs {
    div {
        input {
            padding-top: 13px;
            padding-bottom: 13px;
        }
        &:first-of-type {
            input {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        &:last-of-type {
            input {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: 0;
            }
        }
    }
    &--fluid {
        width: 100%;
        div {
            input {
                width: 100%;
            }
        }
    }
}
