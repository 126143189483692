.sidebar {
    position: relative;
    max-width: 342px;

    &__header {
        margin-bottom: 18px;
        padding: 0;

        position: relative;
        left: -4px;

        font-weight: 600;
        font-size: 1.125rem;
    }
    &__text {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 21px;
    }
    &__link {
        display: block;
        margin-bottom: 15px;
        position: relative;

        color: var(--main-color);
        font-weight: 700;
        font-size: 0.75rem;
        .icon {
            position: absolute;
            left: -30px;
            top: -1px;
        }
    }

    @media screen and (max-width: 850px) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}
