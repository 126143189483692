.support-page {
    .form-control {
        text-align: left !important;
    }
    .support-form {
        width: 100%;
        height: 60px;
        &__container {
            width: 60%;
        }
    }
    .component-container:first-child {
        .container-content {
            margin: 1rem;
            .faq-block {
                opacity: 0.8;
                &:hover {
                    opacity: 1;
                }
                .card {
                    border: none;
                    .card-body {
                        padding: 1rem;
                    }
                }
            }
        }
    }

    .component-container:last-child {
        .container-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 2rem 2rem;
            .feedback-form {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                .modal-input {
                    width: 700px;
                    height: 200px;
                }
                .btn {
                    margin-top: 1.5rem;
                    width: 75%;
                }
            }
        }
    }
}
