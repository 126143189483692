.header {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
}

.paragraph {
    font-size: 16px;
    margin-bottom: 30px;
}

.table {
    margin-bottom: 50px;
}
