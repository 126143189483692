$navigation-background: var(--main-color);
$link-background: #eaedf3;

.base-link {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 4px;

    font-weight: 400;
    font-size: 0.8125rem;
    color: white;

    transition: background-color 200ms;
    &:hover {
        background-color: rgba(234, 237, 243, 0.1);
        text-decoration: none;
    }
}

.navigation {
    padding: 20px 11px 10px 12px;
    flex-basis: 253px;
    flex-shrink: 0;
    overflow: auto;

    background-color: $navigation-background;
    .logo {
        margin-bottom: 55px;
        text-align: center;
    }
    :global(.link) {
        @extend .base-link;
        padding-left: 47px;
    }
    :global(.link-disabled) {
        @extend .base-link;
        padding-left: 47px;
        color: var(--gray);
    }
    :global(.section-link) {
        @extend .base-link;
        margin-top: 30px;
        font-size: 0.875rem;
        :global(.section-link__icon) {
            display: flex;
            padding-right: 15px;
            padding-left: 13px;
            svg {
                path {
                    fill: $link-background;
                }
            }
        }

        @media (max-width: 500px) {
            font-size: 1.2rem;
        }
    }

    .section-links {
        margin-bottom: 23px;
    }

    :global(.link.active),
    :global(.section-link.active) {
        background-color: $link-background;
        color: $navigation-background;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        font-weight: 600;
        svg {
            path {
                fill: $navigation-background !important;
            }
        }
    }

    @media screen and (max-width: 500px) {
        display: none;
    }
}
