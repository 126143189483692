.wrapper {
    margin-bottom: 54px;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    @media screen and (max-width: 850px) {
        flex-direction: column;
        gap: 20px;
    }

    .controls {
        display: flex;
        gap: 24px;

        .controls-arrows-wrapper {
            display: flex;
            gap: 24px;

            align-items: center;

            button {
                border: none;
                outline: none;
                background: transparent;
            }
        }
    }

    .size-selector {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.highlight {
    font-weight: 700;
}

.total-count-wrapper,
.controls,
.size-selector {
    font-size: 14px;
}

.select {
    padding: 0 8px 0 8px;
    display: flex;
    gap: 20px;
}

.title {
    user-select: none;
}
