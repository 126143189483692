.einvoice-filter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: var(--wrapper-color-whit-black);
    margin-bottom: 30px;
    gap: 24px;

    &__search-fields {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &__search-field {
        width: 100% !important;
        flex-basis: auto !important;

        min-width: 216px;
        flex: 1;
        display: flex;
        & > * {
            flex: 1 0 auto;
        }
    }
}
