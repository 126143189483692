.section-card {
    max-width: 335px;
    width: 100%;
    min-height: 240px;
    padding: 31px;
    margin-bottom: 30px;
    border: 0;

    position: relative;

    display: flex;
    flex-direction: column;

    text-align: left;
    background-color: #f9f9fa;
    transition: transform 500ms;
    border-radius: 6px;

    &:focus {
        outline: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &:not(:nth-of-type(2n)) {
        margin-right: 30px;
    }

    @media screen and (max-width: 1300px) {
        &:not(:nth-of-type(2n)) {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 600px) {
        min-height: 270px;
    }

    .title {
        font-size: 1.125rem;
        padding-bottom: 20px;
        margin: 0;
        font-weight: 600;
        line-height: 20px;
    }

    .description {
        color: var(--main-color);
        font-size: 0.75rem;
    }

    .icon {
        position: absolute;
        bottom: 28px;
    }
}
