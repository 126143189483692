.search-header {
    margin-bottom: 25px;

    font-size: 1rem;
    font-weight: normal;
}

.navigation {
    display: flex;
    flex-wrap: wrap;
}
