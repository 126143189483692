.company-select {
    width: 240px;
    flex-basis: 240px;

    .css-1aya2g8 {
        background-color: white;
        height: var(--input-height);
        border: var(--gray-border);
    }

    .react-select__control {
        max-width: fit-content;
    }
}
