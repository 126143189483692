.status-terminal {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 10px;
    margin-bottom: 24px;
    background: var(--selected-bg-color);
    border-radius: 6px;

    span {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }

    .active {
        svg {
            fill: var(--green-check-color);
        }
    }

    .inactive {
        svg {
            fill: var(--green-check-color);
        }
    }

    .disabled {
        svg {
            fill: var(--red-xmark-color);
        }
    }

    p {
        margin: 0;
    }
}

.title {
    font-weight: 700;
}

.field {
    min-height: 24px;
}

.last-field {
    min-height: 24px;
    margin-bottom: 32px;
}
