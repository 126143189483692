.settlement-details {
    &__sum-view {
        color: var(--wrapper-color-whit-black);
        display: flex;
        width: fit-content;
        margin-bottom: 1rem;

        @media (max-width: 930px) {
            display: block;
            width: 100%;
        }
        .card {
            border: var(--gray-border);
            margin-bottom: 1rem;
            margin-right: 1rem;
            @media (max-width: 930px) {
                margin-right: 0;
            }
            .sum-view-container {
                font-family: var(--font-family);
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                color: #333;
                margin-bottom: 0;
                white-space: nowrap;
            }
        }
    }
}
