.operation-sbp-details {
    &__body {
        margin-bottom: 60px;
        @media (max-width: 500px) {
            margin-bottom: 40px;
        }
    }
}

.modal-field-title {
    margin-top: 40px !important;
}
