.new-password-input {
    label {
        width: 100%;

        div {
            width: 100%;
        }
    }

    // обнуление стилей bootstrap
    div {
        margin-bottom: 0;
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
}
