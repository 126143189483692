.header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    max-width: 1410px;
    margin-top: 13px;
    margin-bottom: 30px;

    @media screen and (max-width: 500px) {
        padding-left: 0;
        margin: 25px 16px;
        .menu {
            display: none;
        }
    }
}

.page-title {
    align-self: center;
    font-size: 1.375rem;
    font-weight: 600;

    .title-button {
        width: fit-content;
        padding: 5px 16px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        border-radius: 20px;
        background: #eaedf3;

        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: var(--main-color);
        cursor: pointer;
        text-decoration: none;
    }
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        display: none;
    }

    & > div:first-child {
        margin-right: 33px;
    }

    .user-dropdown-header {
        display: flex;
        align-items: center;
        text-align: left;
        &__user-name {
            margin-left: 14px;
        }
    }
}
