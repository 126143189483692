.links {
    margin: 20px 0 0 10px;

    a {
        display: block;
        color: var(--main-color);
        font-size: 1rem;
        margin-bottom: 15px;
    }
}
