.page {
    display: flex;
    height: 100vh;

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
}

.main-part {
    @media screen and (max-width: 500px) {
        z-index: 1;
        position: fixed;
        top: 54px;
        right: 0;
        left: 0;
        bottom: 0;
    }

    position: relative;
    max-height: 100%;
    flex-basis: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.content {
    flex: 1 1 auto;
}

.footer {
    position: relative;
    z-index: 1;
    border-top: 1px solid #f0f2f2;
}

.container {
    max-width: 1410px;
    padding: 0 85px 0 97px;

    @media screen and (max-width: 1199px) {
        padding: 0 50px 0 60px;
    }
    @media screen and (max-width: 991px) {
        padding: 0 30px 0 40px;
    }
    @media screen and (max-width: 767px) {
        padding: 0 20px 0 30px;
    }
    @media screen and (max-width: 500px) {
        padding: 0 16px 0 16px;
    }
}
