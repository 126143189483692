.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;

    border-radius: 6px;
    border: 1px solid var(--Gray-5, #e0e0e0);

    gap: 21px;

    .text {
        color: var(--Gray-2, #4f4f4f);
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    .link {
        color: var(--Dark-blue, #002e5a);
    }
}

.bottom-text {
    @extend .text;
    margin-top: -15px;
    margin-bottom: 0;
}
