.terminal {
    position: relative;
}

.title-with-selector {
    display: flex;
    gap: 12px;
    align-items: center;

    &.no-icon {
        gap: 36px;
    }
}

.selected {
    background-color: #f2f2f2;
}

.filter-row {
    user-select: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;

    > .name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &.selected {
        background-color: #f2f2f2;
    }
}

.disabled {
    color: #0000004d;
}
