.switcher {
    margin-bottom: 22px;
}

.switcher-button {
    padding: 7px 12px;
    border-radius: 6px;
    border: 0;

    background-color: transparent;
    font-size: 0.75rem;
    color: var(--main-color);
    &:hover {
        cursor: pointer;
    }
    &--active {
        background-color: var(--main-color);
        color: #fff;
        font-weight: bold;
    }
    &:not(:last-of-type) {
        margin-right: 6px;
    }
}
