.common-table {
    &.with-total {
        padding-top: 21px;
    }

    .table-header {
        text-align: left;
        color: #6a6a6a;
        border-bottom: 1px solid #002e5a;
        font-size: 0.75rem;
        th {
            border: none;
            white-space: nowrap;
        }
    }
    .table {
        margin: 0;
        tbody {
            .lastSelected {
                background-color: var(--current-row) !important;
            }
            .hover {
                cursor: pointer;
                &:hover {
                    background-color: rgba(239, 239, 243, 0.4);
                }
            }
            tr {
                td {
                    border-top: 0;
                    border-bottom: 1px solid #f0f2f2;
                    padding: 2px 0;
                    color: #000000;
                    white-space: nowrap;
                    text-align: left;
                    font-size: 1rem;
                }
                a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0.75rem;
                    height: 100%;
                    text-decoration: none;
                    display: block;
                    color: #000000;
                }
            }
        }
    }
    &__no-data {
        margin: 1rem;
        display: block;
        text-align: center;
    }
}

.total-count-wrapper {
    position: absolute;
    font-size: 14px;
    .highlight {
        font-weight: 700;
    }
}

.actions-wrapper {
    position: absolute;
    right: 0px;
}

.table-header-wrapper {
    height: 32px;
    left: 0;
    position: sticky;
}
