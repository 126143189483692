.layout {
    .top-content {
        width: 100%;
        max-width: 700px;
        padding-top: 20px;
        padding-bottom: 60px;
    }
    .content-wrapper {
        display: flex;
        @media screen and (max-width: 850px) {
            flex-direction: column;
        }
        .content {
            width: 100%;
            max-width: 700px;
            margin-right: 136px;
            @media screen and (max-width: 850px) {
                margin-right: 0;
            }
            .title {
                margin-bottom: 35px;

                font-size: 1.375rem;
                font-weight: 600;
            }
        }
    }
}
