label {
    display: flex !important;
    align-items: center;
    width: 100%;
    margin: 0 !important;
    cursor: pointer;
}

.default-checkbox {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.custom-label {
    display: block;
    flex: 0 0 50%;
    padding-right: 16px;

    &.reverse {
        flex: 1 1 50%;
        margin-left: 15px;
        padding-right: 0;
    }
}

.custom-checkbox {
    display: block;
    width: 24px;
    height: 24px;
    overflow: hidden;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 80%;
    border-radius: 4px;
    border: 2px solid #bababa;

    &.left {
        margin-left: 0;
    }

    &.right {
        margin-left: auto;
    }
}

// checked
.default-checkbox:checked + .custom-checkbox {
    background-color: var(--main-color);
    background-image: url('./check-icon.svg');
}

// hover
.default-checkbox:hover + .custom-checkbox {
    border: 2px solid var(--hover-color);
}

// disabled
.default-checkbox:disabled + .custom-checkbox {
    border: 2px solid var(--disabled-color);
}

// checked disabled
.default-checkbox:checked:disabled + .custom-checkbox {
    background-color: var(--disabled-color);
}
