@mixin fill-svg($color) {
    svg {
        path {
            fill: $color;
        }
    }
}

$light-gray: rgba(32, 32, 32, 0.6);
$text-color: #202020;
$link-color: #002e5a;

.footer {
    max-width: 1410px;
    height: 100%;
    padding: 45px 0 23px 0;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    font-size: 0.75rem;

    .bank-info {
        flex: 1 1 260px;
        max-width: 370px;
        padding: 0 20px 0 0;
        display: flex;
        flex-direction: column;

        .logo-wrapper {
            display: inherit;
        }

        .license {
            max-width: 270px;
            margin-left: 16px;
            display: inherit;
            color: $light-gray;
        }

        .link {
            margin: 55px 0 0 65px;
            color: $link-color;
        }
    }

    .contacts {
        flex: 1 1 135px;
        max-width: 215px;
        margin-left: 25px;
        padding-right: 20px;
        min-width: fit-content;

        .phone-numbers {
            position: relative;

            a {
                display: block;
                font-size: 1rem;
                font-weight: 600;
                color: $text-color;
            }
        }

        .call-icon {
            position: absolute;
            left: -25px;
            top: 3px;
        }

        h4 {
            margin-bottom: 15px;
            font-size: 0.75rem;
            color: $light-gray;
        }
    }

    .help {
        min-width: fit-content;
        min-height: 104px;
        padding-top: 29px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        a {
            color: $link-color;
        }
    }

    .media-and-version-wrapper {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
        flex: 1 1 auto;

        .version-button {
            // might be different color
            &:is(.version-button--bank):hover {
                cursor: pointer;
                text-decoration: underline;
            }
            color: $link-color;
            border: none;
            background-color: transparent;

            &:focus:not(:focus-visible) {
                outline: 0;
            }

            &:focus-visible {
                outline: 1;
            }
        }

        .social-media {
            max-height: 58px;
            flex: 1 1 auto;
            padding-top: 29px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            a {
                &:not(:first-of-type) {
                    margin-left: 30px;
                }
            }

            svg {
                &:hover,
                &:focus,
                &:active {
                    path {
                        fill: #ebdfce;
                    }
                }

                path {
                    fill: #cabfad;
                    transition: fill 400ms;
                }
            }
        }
    }

    &--dark-theme {
        background-color: var(--main-color);
        border-color: var(--main-color);
        color: white;

        .bank-info {
            .license {
                color: white;
                opacity: 0.6;
            }

            @include fill-svg(white);

            .link {
                margin-left: 54px;
                margin-top: 25px;
                color: white;
            }
        }

        .contacts {
            h4 {
                color: white;
                opacity: 0.6;
            }

            .phone-numbers {
                a {
                    color: white;
                }

                @include fill-svg(white);
            }
        }

        .help {
            a {
                color: white;
            }
        }

        .media-and-version-wrapper {
            .version-button {
                color: white;
            }

            .social-media {
                svg {
                    &:hover,
                    &:focus,
                    &:active {
                        path {
                            opacity: 1;
                            fill: white;
                        }
                    }

                    path {
                        fill: white;
                        opacity: 0.6;
                        transition: opacity 400ms;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        text-align: center;
        padding: 8vw;
        flex-direction: column;
        align-items: center;

        .bank-info {
            .license {
                text-align: left;
            }
            .link {
                margin: 10px 0 0 0;
                text-align: center;
            }
        }

        .bank-info,
        .help,
        .contacts {
            flex: 0 0 auto;
            margin: 0 0 20px 0;
            padding: 0;
        }

        .help {
            min-height: 75px;
        }
        .media-and-version-wrapper {
            .social-media {
                margin: 0;
                padding: 0;
                align-self: center;
            }
        }

        .media-and-version-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            justify-content: space-between;
            height: 100%;
        }
    }
}

.footer {
    @media screen and (max-width: 1199px) {
        padding: 45px 50px 23px 60px;
    }
    @media screen and (max-width: 991px) {
        padding: 30px 30px 23px 40px;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 20px 23px 30px;
    }
    @media screen and (max-width: 500px) {
        padding: 20px 16px 20px 16px;
    }
}
