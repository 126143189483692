.custom-selected {
    height: 120px !important;
    display: flex !important;
    overflow-y: scroll;
    flex-direction: column;
    padding: 10px;
    text-align: left;
    font-size: 1em;
    span {
        height: auto;
        margin-bottom: 1px;
        border-radius: 10px;
        border: 1px solid transparent !important;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.selected {
    box-shadow: 0 0 10px 100px #174882 inset;
    color: white;
    border: 1px solid #f5f5f5 !important;
}
