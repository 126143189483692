.company-details {
    .einvoce-table-title {
        font-weight: 600;
        font-size: 1.375rem;
        color: #333;
        margin-bottom: 12px;
    }

    .einvoce-filter-date-picker {
        left: 0;
    }

    .string {
        span {
            &:hover {
                color: #ea7682;
            }
        }
    }

    .mdi {
        padding-right: 1rem;
    }

    .role-icon {
        font-size: 1.5rem !important;
    }

    .mdi-remove-circle-outline,
    .mdi-history {
        color: darkgray;
        pointer-events: none;
        font-size: 21px;
    }

    .ecomm-info {
        align-items: center;

        span {
            font-size: 15px !important;
            color: unset !important;
        }
    }

    &__info {
        display: flex;
        flex-flow: column;
        padding: 1rem;

        &__box {
            padding: 0.5rem;
            display: flex;

            span:first-child {
                font-size: 18px;
                color: #333;
            }

            a:hover {
                text-decoration: none;
                color: var(--main-color);
            }

            span:last-child,
            a {
                padding-left: 2rem;
                font-size: 18px;
                color: #333;
                text-overflow: ellipsis;
            }
        }
    }

    &__info-container {
        display: flex;
        flex-wrap: wrap;

        .component-container {
            width: 50%;

            &:last-child span:first-child {
                width: 350px;
            }
        }
    }

    &__button {
        width: 100%;
        margin-top: 2rem;
    }
}

.ecomm-key-button {
    display: flex;
    justify-content: center;
    padding: 10px;

    button {
        width: 200px;
    }
}

.ecomm-modal {
    height: 500px;
    overflow: auto;

    .user-add-form {
        position: absolute;
        top: 0;
        left: 20%;
        margin-bottom: 20px;
    }
}

.add-user-modal,
.user-history-modal {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .modal-input {
        margin-top: 1rem;
    }

    .m05 {
        margin-top: 0.5rem;
    }

    .user-add-form,
    .user-history-form {
        max-width: 300px;
        width: 100%;
    }

    .user-history-form {
        &__info span {
            padding-right: 5px;
        }
    }

    .user-add-form,
    .user-history-form,
    .user-history-header {
        &__info {
            margin: 1rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;

            .card-disabled {
                opacity: 0.5;
            }
        }

        .list-group-item {
            padding: 0.5rem;
        }

        .column {
            flex-flow: column;
        }
    }
}
.company-details__select {
    width: 100%;
    .react-select__control {
        max-width: 100%;
    }
}

.company-details-filter {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-left: 3rem;
    margin-right: 3rem;
    height: 80px;
    justify-content: space-around;
    color: var(--wrapper-color-whit-black);

    .btn {
        height: var(--input-height);
        line-height: 22px;
        font-size: 14px;
        color: white;
        background-color: var(--main-color);
    }
}
