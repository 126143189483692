.trigger {
    position: relative;
    width: 100%;
    min-height: 57px;
    padding: 8px 16px;
    border: 0;

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    background: var(--inactive-color);
    border-radius: 6px;
    color: #000;
    transition: background-color 600ms, box-shadow 600ms, opacity 600ms;

    &__reset-icon {
        display: inline-block;
        position: absolute;
        right: 13px;
        top: 19px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.1);

        svg {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            fill: var(--active-color);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }
    }

    &--open {
        background: var(--active-color);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

        .trigger__reset-icon {
            visibility: hidden;
            opacity: 0;
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }

    &:focus {
        outline: 0;
    }

    &__header {
        font-size: 0.75rem;
    }

    &__count {
        font-size: 0.875rem;
        font-weight: 600;
    }

    @media screen and (max-width: 500px) {
        min-width: 130px;
        min-height: 48px;

        &__header {
            font-size: 0.75rem;
        }

        &__count {
            font-size: 0.75rem;
        }

        .trigger__reset-icon {
            right: 5px;
            top: 17px;
        }
    }
}

.popup {
    opacity: 0;
    visibility: hidden;

    transition: opacity 600ms, visibility 600ms;

    overflow-y: auto;

    &.open {
        opacity: 1;
        visibility: visible;
    }
    padding: 32px;

    background-color: var(--active-color);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 16px;

    .search-field {
        margin-bottom: 8px;
    }

    .terminals {
        min-height: 100px;
        max-height: 212px;
        overflow-y: auto;

        @media (max-width: 767px) {
            max-height: 200px;
        }
    }

    .terminal {
        position: relative;
        padding: 8px;
        padding-left: 46px;
        width: 100%;

        display: flex;
        align-items: center;

        font-size: 0.75rem;
        color: #000;
        opacity: 0.9;

        &:hover {
            cursor: pointer;
        }

        &:not(:last-of-type) {
            margin-bottom: 2px;
        }

        .selected-icon {
            position: absolute;
            left: 13px;
            visibility: hidden;
            svg {
                fill: var(--blue-check-color);
            }
        }

        &--selected {
            background-color: #f9f9fa;
            opacity: 1;
            border-radius: 6px;

            .selected-icon {
                visibility: visible;
            }
        }
    }

    margin-right: calc(
        max(85px + var(--scrollbar-width), 100% - 1663px + 85px)
    );

    @media screen and (max-width: 1662px) {
        margin-right: calc(85px + var(--scrollbar-width));
    }
    @media screen and (max-width: 1662px) {
        margin-right: calc(85px + var(--scrollbar-width));
    }
    @media screen and (max-width: 1199px) {
        margin-right: calc(50px + var(--scrollbar-width));
    }
    @media screen and (max-width: 991px) {
        margin-right: calc(30px + var(--scrollbar-width));
    }
    @media screen and (max-width: 767px) {
        margin-right: 20px;
    }
    @media screen and (max-width: 500px) {
        margin-right: 16px;
        margin-left: 16px;
        max-width: 95vw;
    }
}

.wrapper {
    max-width: 282px;
    flex: 1 0 200px;

    &.disabled {
        opacity: 0.75;
        cursor: not-allowed;
        button {
            pointer-events: none;
        }
    }

    @media screen and (max-width: 991px) {
        flex: 0 0 282px;
    }
}

.actions-wrapper {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        gap: 16px;
    }

    .actions {
        display: flex;
        gap: 24px;

        button {
            outline: none;
            border: 0;
            background-color: transparent;
            font-size: 12px;
            color: #002e5a;
            transition: color 0.25s;

            &:disabled {
                color: #eaedf3;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.controls-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 16px;
    }
}

.hide-overflow-x {
    overflow-x: hidden;
}
