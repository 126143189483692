.date-text-input {
    margin: 0;
    border: 0;
    padding-left: 0;
    width: 120px;
    max-width: 120px;
    max-height: 18px;
    border: none;
    clip-path: inset(0 17px 0 0);
    outline: none;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
    color: #000;
    font-size: 0.875rem;
    font-weight: 600;

    &__safari-fix {
      margin-left: -7px;
      width: calc(120px + 7px);
    }

    &::placeholder {
        opacity: 1;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}

.container {
    position: relative;
    z-index: 1;

    &__hider {
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        position: absolute;
    }
}