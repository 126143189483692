.status {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 10px;
    margin-bottom: 16px;
    background: var(--selected-bg-color);
    border-radius: 6px;

    span {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }

    .active {
        svg {
            fill: var(--green-check-color);
        }
    }

    .disabled {
        svg {
            fill: var(--red-xmark-color);
        }
    }

    p {
        margin: 0;

        &:last-child {
            margin-left: auto;
        }
    }
}
