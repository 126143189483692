.badge {
    position: absolute;
    top: -9px;
    right: -9px;
    z-index: 999;

    max-width: 40px;
    min-width: 18px;
    height: 18px;
    padding: 2px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 11px;
    background-color: #ebebeb; // default props ?
    font-size: 11px;
    line-height: 20px;
    color: #000; // default props ?
    font-weight: 700;

    @media (max-width: 500px) {
        top: -8px;
        right: -8px;
        min-width: 16px;
        height: 16px;
    }
}

.badge {
    &--warning {
        background: #a6192e;
        color: #ffffff;
    }
    &--info {
        background: #002e5a;
        color: #ffffff;
    }
}
