.statistic {
    .bottom-charts {
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        display: flex;
        flex-flow: row;
        justify-content: space-between;
        gap: 3rem;
    }
}