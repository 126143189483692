.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
}

.overlay {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--overlay-color);
}

.content {
    position: absolute;
    top: -100%;
    max-width: 550px;
    width: 100%;
    max-height: calc(100vh - 20%);
    padding: 25px 0 25px 24px;
    background: var(--bg-white);
    border-radius: 12px;
    transition: 0.7s top;
    display: flex;
    flex-direction: column;
}

.opened {
    z-index: var(--modal-z-index);
    opacity: 1;
    pointer-events: auto;

    .content {
        top: 10%;
    }
    .confirm {
        top: 28%;
        max-width: 500px;
    }
}

.closing {
    .content {
        top: -100%;
    }
}

.header {
    position: relative;
    margin-bottom: 20px;
    padding-left: 1px;
    padding-right: 40px;
}
.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--wrapper-color-whit-black);
}
.close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 30px;
    width: 10px;
    height: 10px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        border-radius: 1px;
        background: var(--main-color);
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}

.body {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 17px 10px 1px;
}

.footer {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 8px;
    padding: 20px 26px 0 0;
}

@media (max-width: 575px) {
    .header {
        margin-bottom: 15px;
    }

    .content {
        max-width: 94vw;
        padding: 15px 0 15px 14px;
    }

    .footer {
        padding: 10px 26px 0 0;
    }
}
