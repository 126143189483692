.switcher {
    min-height: 48px;
    padding: 4px;
    display: flex;

    background-color: #f2ede4;
    border-radius: 6px;
    &-element {
        min-width: 138px;
        padding: 8px;

        background-color: transparent;
        color: #000;
        border-radius: 6px;
        border: 0;
        font-size: 0.75rem;
        font-weight: 700;
        transition: background-color 600ms, color 600ms;
        &--active {
            color: var(--main-color);
            background-color: #fff;
        }
        &:not(:active) {
            cursor: pointer;
        }
        &:focus {
            outline: 0;
        }
    }
    @media screen and (max-width: 500px) {
        min-height: 48px;
        min-width: 100%;

        &-element {
            min-width: 50%;
        }
    }
}

