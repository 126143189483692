// TODO После удаления всех старых модалок удалить все стили которые они использовали

.merchant-user-list-page {
    .mdi {
        padding-right: 1rem;
    }

    .aware-table-header__actions {
        width: 100%;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }
}
.error-msg {
    color: red;
    margin-top: 1em;
    display: flex;
    flex: 1 1;
    justify-content: center;
}
.terminals-group-form {
    height: auto !important;
}
.add-merchant-user-modal {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .modal-input {
        margin-top: 1rem;
    }

    .m2 {
        margin-top: 2rem;
    }

    .m1 {
        margin-top: 1rem;
    }

    .btn-secondary {
        width: 100%;
    }

    .user-add-form {
        width: 100%;
        &__info {
            margin: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 16px;
            margin-left: 0 !important;
            margin-right: 0 !important;
            .term-title {
                margin-bottom: 16px;
            }
        }
        .list-group-item {
            padding: 0.5rem;
        }
        .custom-selected {
            border: 1px solid #ebebeb;
            margin-top: 0.5rem;
            max-width: 100%;
        }
        .column {
            flex-flow: column;
        }

        .custom-selected {
            margin-top: 1rem;
            padding-left: 0;
        }

        .select-wrapper {
            margin-top: 1rem;
            width: 300px;
            min-width: 300px;
            max-width: 300px;
        }
        .dropdown-menu {
            width: 300px;
        }
    }
}

.merchant-user-list-filter {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;

    &__item {
        display: flex;
        align-items: center;
        span {
            margin-right: 1rem;
        }
    }
}
