.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    max-height: 32px;
    padding: 6px 17px;
    border: 1px solid #dfe5eb;
    border-radius: 16px;
    background: transparent;
    font-size: 0.75rem;
    color: var(--main-color);
    cursor: pointer;

    &:active,
    &:focus {
        outline: 0;
    }
}
