.mps-chart {
    &__total {
        font-size: 0.75rem;
        display: flex;
        padding-top: 10px;
        &-text {
            font-weight: 700;
            margin-left: 4px;
        }
    }

    &__canvas {
        position: relative;
        width: 100%;
        height: 50px;
    }

    &__ps {
        display: flex;
    }
    &__ps-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-amount {
            font-size: 1rem;
            font-weight: bold;
        }
        &-logo {
            margin-bottom: 6px;
        }
    }

    &__legend {
        display: flex;
        flex-flow: column;
        &__row {
            height: 34px;
            padding: 0 1rem;
            font-family: var(--font-family);
            color: var(--wrapper-color-whit-black);
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            margin-right: 1rem;
            font-size: 16px;
            width: 100%;

            &__legend-color {
                height: 3px;
                width: 22px;
                margin-right: 10px;
            }
            .img-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 44px;
                margin-right: 10px;
                img {
                    width: 44px;
                    height: 15px;
                }
            }
            span {
                margin-left: auto;
            }
        }
        &__row:nth-child(1) {
            .img-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 44px;
                img {
                    width: 31px;
                    height: unset;
                }
            }
        }
    }
}
