.wrapper {
    margin-bottom: 25px;
    color: #333333;
}
.title {
    font-weight: 700;
}

.icon {
    margin-bottom: 12px;
}
