.receipt-modal {
    padding-left: 3rem !important;
    padding-right: 3rem !important;

    .modal-input {
        margin-top: 1rem;
    }

    .btn {
        margin-top: 1rem;
    }

    .m05 {
        margin-top: 0.5rem;
    }

    .m2 {
        margin-top: 2rem;
    }

    .bank-form {
        max-width: 300px;
    }

    .btn-secondary {
        width: 100%;
    }
}

.receipt-form {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #e9ecef;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        font-size: 16px;

        .main-header {
            margin-top: 1rem;
        }

        h5 {
            color: black;
            font-weight: bold;
        }

        &-info {
            &:nth-of-type(1) {
                margin-top: 0.3rem;
            }

            margin-bottom: 0.3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
        }
    }
    &__footer {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        margin-bottom: 30px;
        h5 {
            margin: 50px;
            font-weight: bold;
        }
        .text,
        h5 {
            color: black;
        }
        &-content {
            align-self: flex-end;
        }
    }
    &__info {
        font-size: 16px;
        width: 100%;
    }

    &__info span {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            width: 50%;
        }
    }

    &__item {
        span {
            margin-bottom: 0.3rem;
            display: inline-flex;
            flex-direction: column;
        }

        &-header {
            color: black;
            font-size: 1.1rem;
        }

        &-quantity {
            font-size: 16px;
            display: flex;
            justify-content: flex-end;
        }
        &-content span {
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            width: 100% !important;
            display: flex !important;
            flex-direction: row !important;
            justify-content: space-between !important;
            font-size: 16px;

            .text {
                width: 50% !important;
            }
        }
    }

    .list-group-item {
        padding: 0.5rem;
    }

    .column {
        flex-flow: column;
    }
}

.group-list-filter {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;

    &__item {
        display: flex;
        align-items: center;

        span {
            margin-right: 1rem;
        }
    }
}
