.aware-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 16px;
    padding-left: 12px;

    &__count {
        font-size: 0.75rem;

        span {
            font-weight: 600;
        }
    }

    &__actions {
        &:hover {
            cursor: pointer;
        }
    }
}
