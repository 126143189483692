.wrapper {
    flex-shrink: 0;

    .checkmark {
        opacity: 0;
        transition: all 0.25s;
        &.selected {
            opacity: 1;
        }

        &.hide {
            opacity: 0;
        }
    }
}
