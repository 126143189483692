.wrapper {
    .title {
        position: relative;
        padding: 0 10px;
        color: #000000;

        svg {
            position: absolute;
            top: 1px;
            left: -20px;
            fill: #cabfad;
        }
        div {
            font-weight: 700;
        }
    }

    .subtitle {
        padding: 0 10px;
    }
}
