.modal {
    border: none !important;
    border-radius: 10px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);

    :global .react-datepicker {
        border: none !important;
        border-radius: 10px !important;

        &__month-container {
            border-radius: 10px !important;
            overflow: hidden;
        }

        &__triangle:before {
            border: none !important;
        }

        &__header {
            padding: 8px 0;
            overflow: hidden;
            border-radius: 10px 10px 0 0;
            background-color: white;
            border: none;
        }

        &__day-names {
            display: none;
        }

        &__month {
            border: none;
            border-radius: 0 0 10px 10px;
            overflow: hidden;
        }

        &__day {
            cursor: pointer;
        }
        &__day--selected {
            background-color: var(--main-color);
        }
        &__day--selected:hover {
            background-color: var(--main-color);
        }
    }
}
