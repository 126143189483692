.bank-user-list-page {
    .mdi {
        padding-right: 1rem;
    }
}
.error-msg {
    color: red;
    margin-top: 1em;
    display: flex;
    flex: 1 1;
    justify-content: center;
}
.add-bank-user-modal {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .modal-input {
        margin-top: 1rem;
    }
    .btn {
        margin-top: 2rem;
    }
    .btn-secondary {
        width: 100%;
    }

    .user-add-form {
        max-width: 300px;
        width: 100%;
        &__info {
            margin: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
        }
        .list-group-item {
            padding: 0.5rem;
        }
        .custom-select {
            margin-top: 1rem;
            width: 100%;
        }
        .column {
            flex-flow: column;
        }
    }
}

.bank-user-list-filter {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;

    &__item {
        display: flex;
        align-items: center;
        span {
            margin-right: 1rem;
        }
    }
}
