.wrapper {
    margin-bottom: 30px;

    // set large width here so filter gets placed on new row on screen width decrease
    .filter-wrapper {
        display: flex;
        gap: 20px;
        align-items: flex-end;

        @media screen and (max-width: 1300px) {
            width: 500px;
        }
    }
}

.actions-wrapper {
    display: flex;
    gap: 20px;
    align-items: flex-end;
}

.company-select {
    :global(.react-select__control) {
        max-width: 100%;
    }
}
