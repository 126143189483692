.help-link {
    position: relative;
    padding-left: 27px;
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
    a {
        font-family: var(--font-family);
        color: #002e5a;
        font-size: 0.75rem;
    }
    svg {
        left: 0;
        position: absolute;
        height: 16px;
    }
}
