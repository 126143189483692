.title {
  margin-top: 40px !important;
}

.einvoice-item {
  padding: 0 10px;
  margin-bottom: 6px !important;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
}

.error-keys {
  text-align: center;
  color: var(--error);
}

// растягивает инпут во всю ширину контейнера
.datepicker {
  & > div:last-child > div > div:first-child {
      width: 100%;
      div {
          width: 100%;
      }
  }
}
