.settings-page {
    &__admin-settings {
        .component-container {
            .container-content {
                .merchant-user-list-filter {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    &__navigation {
        display: flex;
        flex-flow: column;
        text-decoration: none;
    }
}
