.user-settings-page {
    &__body {
        margin-bottom: 60px;
        @media (max-width: 500px) {
            margin-bottom: 40px;
        }

        .button {
            margin-top: 40px;
        }

        .documents {
            padding: 15px 0 0 20px;
        }
    }

    &__modal {
        .modal-content {
            border-radius: 12px;
            box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.25);
            border: 0;
            max-width: 367px;

            .modal-header {
                border-bottom: 0;
                padding: 26px 30px 24px 30px;
            }

            .modal-body {
                padding: 25px 30px;

                button {
                    margin-top: 30px;
                }
            }
        }
    }
}
