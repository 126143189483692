.wrapper {
    max-width: 384px;
    width: 100%;
    padding: 21px 21px 33px 21px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
    background: #ffffff;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    color: #333333;

    .green {
        color: darkgreen;
        font-weight: bold;
    }
}

.amount {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    color: var(--main-color);
}

.sbp {
    margin-top: 30px;
}

.buttons {
    margin-top: 30px;
    padding: 0 12px;

    button {
        width: 100%;
        margin-bottom: 17px;

        &:last-child {
            margin: 0;
        }
    }
}
