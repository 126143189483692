.date-range-picker {
    position: relative;
    transition: box-shadow 600ms;

    background-color: var(--inactive-color);
    border-radius: 6px;

    .controls-wrapper {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &:hover,
    &:focus-within {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }
    &__headers {
        display: flex;
        min-height: 57px;
    }
    &__header {
        min-width: 141px;
        padding: 0px 17px;
        margin: 8px 0;

        display: flex;
        flex-direction: column;
        justify-content: center;

        background-color: transparent;
        border: 0;
        &--active {
            margin: 0;

            background-color: var(--active-color);
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
            border-radius: 6px;

            transition: background-color 600ms, box-shadow 600ms;
            &:first-of-type {
                border: 0;
            }
        }
        &:focus {
            outline: 0;
        }
        &:hover {
            cursor: pointer;
        }
        &:first-of-type {
            border-right: 1px solid var(--active-color);
        }
        .title {
            color: #000000;
            font-size: 0.75rem;
            text-align: left;
        }
    }

    &__popup {
        position: absolute;
        left: -133px;
        z-index: 5;
        top: 67px;
        min-height: 322px;
        padding: 24px 34px;

        visibility: hidden;
        opacity: 0;
        transition: opacity 600ms, visibility 600ms;

        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        background-color: var(--active-color);

        &--open {
            visibility: visible;
            opacity: 1;
        }

        &--left {
            left: 0;
        }

        .reset-button {
            position: relative;
            z-index: 1;
            padding: 5px;
            border: 0;
            background-color: transparent;
            color: var(--main-color);
            font-size: 0.75rem;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 991px) {
        &__popup {
            left: -16px;
        }
    }

    @media screen and (max-width: 500px) {
        &__header {
            min-width: 84px;
            .title {
                font-size: 0.75rem;
                font-weight: 400;
                text-align: left;
            }
            .date {
                font-size: 0.75rem;
                font-weight: 600;
                text-align: left;
            }
        }
        &__headers {
            min-height: 48px;
        }

        &__popup {
            left: 0;
        }

        &__datepickers {
            flex-wrap: wrap;
        }
    }

    // React Datepicker styles
    $day-size: 32px;
    $border-radius: 6px;
    $outside-selecting-range-bg: #95b0c5;
    $selecting-range-bg: #f9f9fa;

    @mixin reset() {
        background-color: transparent !important;
        border: 0 !important;
    }

    @mixin rounded-right-border() {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    &__datepickers {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .date-picker-calender {
            min-height: 226px;
            min-width: 228px;
        }
        :global {
            .react-datepicker {
                font-family: var(--font-family);
                font-size: 0.75rem;
                // height to compensate for shift
                height: 260px;
                @include reset();
                &__header {
                    text-transform: capitalize;
                    @include reset();
                }
                &__month {
                    // move in place of hidden week days
                    position: relative;
                    top: -28px;
                }
                &__day-name {
                    width: $day-size;
                    visibility: hidden;
                }
                &__day {
                    width: $day-size;
                    height: $day-size;
                    padding: 4px;

                    border-radius: $border-radius;
                    opacity: 0.4;
                    &:hover {
                        background-color: var(--main-color);
                        color: #ffffff;
                    }
                    &--outside-month {
                        opacity: 0;
                        visibility: hidden;
                    }
                    &--in-selecting-range {
                        position: relative;
                        background-color: $outside-selecting-range-bg;
                        opacity: 1;
                        &::after {
                            display: block;
                            width: 15px;
                            height: $day-size;
                            content: '';
                            position: absolute;
                            right: -10px;
                            top: 0;
                            z-index: -1;

                            background-color: $outside-selecting-range-bg;
                            opacity: 1;
                        }
                        &:hover {
                            background-color: $outside-selecting-range-bg;
                        }
                        &:last-of-type {
                            &::after {
                                @include rounded-right-border();
                            }
                        }
                        &:not(.react-datepicker__day--in-range) {
                            background-color: $outside-selecting-range-bg;
                        }
                    }
                    &--selecting-range-end {
                        &::after {
                            @include rounded-right-border();
                        }
                    }
                    &--in-range {
                        opacity: 1;
                        background-color: $selecting-range-bg;
                        color: #000;
                        position: relative;

                        &:hover {
                            background-color: $selecting-range-bg;
                            color: #000;
                        }

                        &::after {
                            display: block;
                            width: 15px;
                            height: $day-size;
                            content: '';
                            position: absolute;
                            right: -10px;
                            top: 0;
                            z-index: -1;

                            background: $selecting-range-bg;
                        }
                        &:last-of-type {
                            &::after {
                                @include rounded-right-border();
                            }
                        }
                    }
                    &--range-start {
                        opacity: 1;
                        background-color: var(--main-color) !important;
                        color: #ffffff !important;
                    }
                    &--range-end {
                        opacity: 1;
                        background-color: var(--main-color) !important;
                        color: #ffffff !important;
                        &::after {
                            @include rounded-right-border();
                        }
                    }
                }
            }
        }
    }
}
