$circular-border: 1px solid #dfe5eb;
$circular-background: #ffffff;
$open-background: #ffffff;

.header-wrapper {
    overflow: hidden;
}

.dropdown-item {
    width: 100%;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    position: relative;
    z-index: 8;

    font-size: 0.75rem;
    color: var(--main-color);

    &:hover {
        background-color: #f9f9fa;
        cursor: pointer;
        color: var(--main-color);
    }

    &:first-of-type {
        margin-top: 15px;
    }
}

.dropdown-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--inactive-color);
    border-radius: 16px;
    transition: background-color 400ms;

    .items {
        max-height: 500px;
        width: 100%;
        position: absolute;
        top: 100%;
        z-index: 2;
        opacity: 0;
        border-radius: 0 0 16px 16px;
        background-color: var(--inactive-color);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

        transition: max-height 400ms, background-color 300ms, opacity 50ms;

        .placeholder {
            position: absolute;
            height: 16px;
            top: -16px;
            width: 100%;
            z-index: 0;

            transition: inherit;
            background-color: inherit;
            background-color: var(--inactive-color);
        }

        .dropdown-item:last-child {
            border-radius: 0 0 16px 16px;
        }
    }

    &.outline {
        border-radius: 39px;
        border: $circular-border;
        background-color: $circular-background;
    }

    // стили для header-menu
    &.circular {
        width: 206px;
        padding: 2px 0 2px 2px;
        border-radius: 22px;
        border: $circular-border;
        background-color: $circular-background;
        transition: border-radius 200ms ease-in-out 0ms;

        .trigger {
            padding: 3px;
            width: 100%;
            min-height: 40px;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;
            background-color: $circular-background;
            font-weight: 600;

            &__header {
                span {
                    max-width: 70%;
                    max-height: 34px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                }
            }

            .trigger__arrow {
                margin-right: 10px;
            }
        }

        .items {
            width: 206px;
            top: 50%;
            right: -1px;
            padding-top: 15px;
            border-radius: 0 0 22px 22px;
            border: $circular-border;
            border-top: none;
            background-color: $circular-background;
            overflow: hidden;

            .placeholder {
                height: 0;
            }
        }

        .dropdown__item {
            &:not(:last-of-type) {
                border-bottom: $circular-border;
            }
        }
    }

    &.open {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        background-color: $open-background;

        .items {
            background-color: $open-background;
            opacity: 1;

            .placeholder {
                background-color: $open-background;
            }
        }

        .trigger__arrow {
            transform: rotate(0deg) !important;
        }

        .trigger {
            z-index: 7;
        }
    }

    &.closed {
        .items {
            max-height: 0;
            opacity: 0;
            z-index: -5;
        }
    }

    .trigger {
        width: fit-content;
        max-height: 32px;
        padding: 6px 17px;
        z-index: 0;

        display: flex;
        align-items: baseline;
        justify-content: space-between;
        background: transparent;

        border: 0;
        text-overflow: ellipsis;

        cursor: pointer;
        &:active,
        &:focus {
            outline: 0;
        }

        &__header {
            font-size: 0.75rem;
            color: var(--main-color);
        }

        &__arrow {
            margin-left: 8px;
            position: relative;
            display: flex;
            transform: rotate(180deg);
            transition: transform 400ms;
        }
    }

    .dropdown {
        &__item {
            @extend .dropdown-item;
        }
    }
}

:global(.dropdown__link) {
    @extend .dropdown-item;
    padding: 0 0 0 52px;
    justify-content: flex-start;
    text-align: left;
    &:hover {
        text-decoration: none;
    }
}

:global(.dropdown__button) {
    @extend .dropdown-item;
    padding: 0 0 0 52px;
    margin-bottom: 10px;
    justify-content: flex-start;
    border: 0;
    background-color: transparent;

    & > span {
        margin-right: 10px;
    }
}
