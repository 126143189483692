.platform-cashbox-page {
    .common-table .table-header th.number {
        text-align: left;
    }
    .mdi-edit {
        color: darkgray;
        pointer-events: none;
        font-size: 21px;
    }
}
.user-add-form__info {
    margin-left: 0 !important;
    font-size: 15px !important;
    &-select {
        width: 100%;
    }
}
.platform-cashbox-filter {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;

    &__item {
        display: flex;
        align-items: center;
        span {
            margin-right: 1rem;
        }
    }
}
