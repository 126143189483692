.add-info {
    margin-top: 20px;
}

.add-info__item {
    margin-bottom: 45px;

    @media (max-width: 500px) {
        & > div {
            margin-left: 20px;
        }
    }
}

.links {
    margin-bottom: 20px;
}
