.wrapper {
    max-width: 300px;
    width: 100%;

    user-select: none;
    border-bottom: none;
    position: relative;
    height: 32px;
    flex-shrink: 0;
    cursor: pointer;

    .header {
        z-index: 2;
        padding-left: 8px;
        position: absolute;
        color: #000000;
        font-weight: 700;
        line-height: 14px;
        font-size: 12px;
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
    }

    .content {
        z-index: 1;
        background-color: white;
        position: absolute;
        max-width: 300px;
        width: 100%;
        max-height: 0;
        overflow: hidden;

        transition: 0.25s all;
        border: 1px solid #bdbdbd;
        &.open {
            border: 1px solid #002e5a;
        }

        left: 0;
        top: 0;
        padding-top: 32px;
        border-radius: 6px;

        display: flex;
        flex-direction: column;
        cursor: auto;
    }
}

.option {
    display: flex;
    gap: 8px;
    line-height: 14px;
    cursor: pointer;
    user-select: none;

    padding: 5px 8px 5px 8px;

    &:last-of-type {
        padding-bottom: 8px;
    }
}

.checkmark {
    opacity: 0;
    transition: opacity 0.25s ease;

    &.active {
        opacity: 1;
    }
}
