body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
a {
    font-family: var(--font-family);
}
