.wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 4px;
    
    .terminals {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .terminal {
        position: relative;
    }

    .title-with-selector {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    .organisation {
        font-weight: 700;
    }
}

.selected {
    background-color: #f2f2f2;
}

.filter-row {
    user-select: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;

    > .name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &.selected {
        background-color: #f2f2f2;
    }
}

.disabled {
    color: #0000004d;
}

.no-selected-terminals {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;

    p {
        line-height: 16.94px;
        font-size: 14px;
        color: #bdbdbd;
    }

    .reset-button {
        color: #002e5a;
        cursor: pointer;
    }
}
