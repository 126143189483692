.wrapper {
    position: relative;
    height: 18px;
    width: 18px;
    cursor: pointer;
    svg {
        fill: #002e5a;
    }

    @media screen and (max-width: 500px) {
        svg {
            fill: #ffffff;
        }
    }
}
