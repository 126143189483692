@import './breakpoints.scss';
@import './variables.scss';
@import './bootstrap.scss';
@import './typography.scss';

$color-track: #ffffff;
$color-thumb: #c4c4c4;

@media (min-width: 768px) {
    // Chrome, Edge, Safari, Opera
    div::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }
    div::-webkit-scrollbar-track {
        background: $color-track;
    }
    div::-webkit-scrollbar-thumb {
        background: rgba($color-thumb, 0.5);
        border-radius: 6px;
        border: 2px solid $color-track;
    }
    div::-webkit-scrollbar-thumb:hover {
        background: rgba($color-thumb, 0.8);
    }

    // Firefox
    div {
        scrollbar-width: thin;
        scrollbar-color: $color-thumb $color-track;
    }
}
