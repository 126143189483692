.component-container {
    max-width: 1410px;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 55px;
    min-width: var(--component-min-width);
    color: var(--wrapper-color-whit-black);
    user-select: none;
    &.component-container--background {
        background: #ffffff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        padding: 22px 28px;
    }

    .container-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 6px 6px 0 0;
        gap: 8px;

        margin-bottom: 12px;

        .spin {
            position: relative;
            height: 30px;
            width: 30px;
            margin: 0;
            margin-right: 3rem;
            border: 6px solid white;
            border-top: 6px solid #275a8a;
            border-radius: 50%;
        }

        &__title {
            font-weight: 600;
            font-size: 1.375rem;
            color: #333;
            margin-bottom: 0;
        }

        &__buttons {
            width: auto;
            flex-wrap: wrap;
            display: flex;
            gap: 5px 10px
        }

        .mdi {
            top: 3px;
            cursor: pointer;
        }
        .mdi-2x,
        .mdi-size-2x {
            font-size: 1.5rem;
        }
    }

    .container-content {
        margin-top: 0;
        margin-left: 0;
        overflow-x: unset;
    }

    .common-table {
        padding-bottom: 14px;
        overflow-x: auto;
    }

    .chart {
        margin-top: 1rem;
        margin-left: 0.6rem;
    }

    .container-footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 24px;
        span + span {
            padding-left: 0.4rem;
        }
        .pagination {
            font-size: 12px;
            div {
                padding-left: 6px;
            }
            .pagination-item {
                color: #002e5a;
                cursor: pointer;
            }
            .no-pointer {
                cursor: default;
                padding-right: 0.4rem;
            }
            &__title {
                color: #000 !important;
            }
            &_active {
                font-weight: 700;

                color: #000 !important;
            }
            .hide {
                display: none;
            }
        }
    }

    @-webkit-keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            -webkit-transform: translateY(0);
        }
        40% {
            -webkit-transform: translateY(-16px);
        }
        60% {
            -webkit-transform: translateY(-8px);
        }
    }
    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-16px);
        }
        60% {
            transform: translateY(-8px);
        }
    }

    .bounce {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-name: bounce;
        animation-name: bounce;
    }
}
