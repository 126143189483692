.einvoice-filter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: var(--wrapper-color-whit-black);
    margin-bottom: 30px;

    > div {
        &:not(:last-of-type) {
            margin-right: 24px;
            margin-bottom: 22px;
        }
    }
}
