.wrapper {
    user-select: none;

    .overflow-wrapper {
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    .header {
        .title {
            font-weight: 600;
            font-size: 1.375rem;
            color: #333;
            margin-bottom: 0;
        }

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 6px 6px 0 0;

        margin-bottom: 12px;

        .spin {
            position: relative;
            height: 30px;
            width: 30px;
            margin: 0;
            margin-right: 3rem;
            border: 6px solid white;
            border-top: 6px solid #275a8a;
            border-radius: 50%;
        }

        .buttons {
            width: auto;
            button {
                margin-left: 10px;
            }
        }

        .mdi {
            top: 3px;
            cursor: pointer;
        }
        .mdi-2x,
        .mdi-size-2x {
            font-size: 1.5rem;
        }

        @-webkit-keyframes bounce {
            0%,
            20%,
            50%,
            80%,
            100% {
                -webkit-transform: translateY(0);
            }
            40% {
                -webkit-transform: translateY(-16px);
            }
            60% {
                -webkit-transform: translateY(-8px);
            }
        }
        @keyframes bounce {
            0%,
            20%,
            50%,
            80%,
            100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-16px);
            }
            60% {
                transform: translateY(-8px);
            }
        }

        .bounce {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-name: bounce;
            animation-name: bounce;
        }

        .icon-wrapper {
            min-height: 24px;
            display: flex;
            align-items: center;
        }
    }
}
