.tool-tip {
    background: whitesmoke !important;
    font-size: 16px;

    &__container {
        display: table;
        width: 500px;
        height: auto;
        padding: 15px;

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .close {
                margin-right: 20px !important;
                display: none !important;
            }

            .mdi {
                font-size: 0.9rem;
                margin-right: 5px;
            }

            h5 {
                width: 90%;
                margin-bottom: 0;
                clear: both;
            }
        }
    }
}

@media (max-width: 576px) {
    .custom {
        top: 0 !important;
        left: 0 !important;

        .tool-tip__container {
            max-width: 500px;
            width: unset;

            .header {
                .close {
                    display: block !important;
                    border: none;
                }
            }
        }
    }
}
