.company-details {
    .string {
        span {
            &:hover {
                color: #ea7682;
            }
        }
    }

    #paymentUrl {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .mdi {
        padding-right: 1rem;
    }

    .mdi-content-copy {
        font-size: 20px;
        margin-left: 32px;
        cursor: pointer;
    }
    .copy {
        &-true {
            color: lightslategray;
        }
    }

    .card {
        max-width: 500px;
        margin-left: 10px;

        .company-details {
            &__info {
                &__box {
                    justify-content: space-between;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-flow: column;
        padding: 1rem;

        &__box {
            padding: 0.5rem;
            display: flex;

            span:first-child {
                font-size: 18px;
                color: #333;
            }

            a:hover {
                text-decoration: none;
                color: var(--main-color);
            }

            span:last-child,
            a {
                padding-left: 2rem;
                font-size: 18px;
                color: #333;
                text-overflow: ellipsis;
            }
            .--extended {
                overflow-wrap: break-word;
                width: 70%;
            }
        }
    }
}

.ml5mr5 {
    margin-left: 5px;
    margin-right: 5px;
}
