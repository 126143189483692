.light-table {
    table {
        width: 100%;
        thead {
            color: #000;
            font-weight: 700;
            border-bottom: 1px solid #20486e;
            td {
                padding: 15px 0;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #f0f2f2;
                td {
                    padding: 15px 10px;
                    &:first-of-type {
                        width: 45%;
                        color: #000;
                        opacity: 0.5;
                    }
                    &:nth-of-type(2) {
                        width: 55%;
                        color: #000;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
