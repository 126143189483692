.group-list-filter {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;

    &__item {
        display: flex;
        align-items: center;
        span {
            margin-right: 1rem;
        }
    }
}

.registry-page {
    h1 {
        margin-bottom: 30px;
    }
}
