.wrapper {
    height: fit-content;
    padding: 8px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: #4f4f4f;

    // set max-width so tooltip doesn't overflow viewport
    max-width: 50vw;

    pointer-events: none;

    .title {
        color: #fff;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 8px;

        .circle {
            border-radius: 100%;
            width: 8px;
            height: 8px;
            background-color: #2f80ed;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0 16px 0 16px;

        .text {
            color: #fff;
            font-size: 12px;
            line-height: 14px;
        }

        .icon {
            border-radius: 4px;
            padding: 4px 8px;
            border: 1px solid;
            font-weight: 600;
            line-height: 14px;
            width: fit-content;
            display: flex;
            gap: 8px;

            &.active {
                border-color: #27ae60;
                color: #27ae60;
                background: #27ae601a;
            }

            &.disabled {
                border-color: #da3c5c;
                color: #da3c5c;
                background: #da3c5c1a;
            }

            &.no-ops {
                border-color: #f2c94c;
                color: #f2c94c;
                background: #f2c94c1a;
            }
        }
    }
}
