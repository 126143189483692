.wrapper {
    &:hover {
        cursor: pointer;
    }

    &.disabled:hover {
        cursor: unset;
    }

    path {
        transition: 0.1s fill;
    }
}

.backward {
    transform: rotate(180deg);
}
