.wrapper {
    border-bottom: 1px solid #f0f2f2;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .version-date-wrapper {
        display: flex;
        justify-content: space-between;

        font-size: 14px;
    }

    .title-description-wrapper {
        display: flex;
        flex-direction: column;
        transition: all 0.25s;
        overflow: hidden;
        height: 100%;

        .title {
            font-weight: 500;
        }
        .description {
            font-size: 14px;

            padding-right: 12px;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 7; /*define how many line you want to show */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

            &.open {
                -webkit-line-clamp: unset;
            }
        }

        .changes-list {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-left: 6px;

            div {
                display: list-item;
                list-style-type: disc;
                margin-left: 1em;
            }
        }
    }

    .show-all {
        align-self: flex-end;
        width: fit-content;
        min-height: 20px;
        color: #002e5a;
        font-size: 14px;

        &:hover {
            cursor: pointer;
        }
    }
}
