.popup {
    padding: 12px 4px 12px 12px;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
}

.modal {
    padding: 12px;
    max-width: 85vw;
    margin-top: 5vh;

    & > div:first-child {
        padding: 0;
        max-height: 50vh;
        overflow-y: hidden;
    }
    & > div:last-child {
        padding-top: 10px;
        padding-right: 0;
    }
}

.control {
    padding: 0 8px 10px 0;

    .search {
        height: 38px;
        margin-bottom: 12px;
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.list {
    height: 208px;
    overflow-y: scroll;
    overflow-x: hidden;
}
