:root {
    --main-color: #002d59;
    --hover-color: #1a4a80;
    --disabled-color: rgba(0, 46, 90, 0.1);
    --inactive-color: #f2ede4;
    --overlay-color: rgb(0 0 0 / 50%);
    --input-bg-color: #fbfbfb;
    --input-border-color: #bdbdbd;
    --active-color: #ffffff;
    --wrapper-color-whit-black: #333333;
    --dark-gray: #7a7a7a;
    --icon-gray: #757575;
    --light-gray: #f5f5f5;
    --active-group: #f8f9fa;
    --gray: #dddddd;
    --gray-border: 1px solid #f5f5f5;
    --bg-white: white;
    --tx-white: white;
    --component-min-width: 330px;
    --container-max-width: 1340px;
    --main-page-header: 50px;
    --input-height: 38px;
    --current-row: rgb(206, 220, 248);
    --middle-window-width: 1280px;
    --error: #da3c5c;
    --mobile: 500px;

    /* цвета текста */
    --primary-text-color: #000;
    --inverted-primary-text-color: #fff;
    --secondary-text-color: #002e5a;
    --opacity-secondary-text-color: rgba(0, 46, 90, 0.7);
    
    /* цвета иконок */
    --green-check-color: #24A23F;
    --blue-check-color: #002E5A;
    --red-xmark-color: #a6192e;

    /* цвета фильтров */
    --selected-bg-color: #f9f9fa;

    /* z-индексы */
    --modal-z-index: 10;
    --select-z-index: 1000;

    /* шрифт */
    --font-family: 'Inter', Arial, Helvetica, sans-serif;
}
