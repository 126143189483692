.wrapper {
    max-width: 550px;
    width: 100%;
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    padding: 8px;
    border-radius: 6px;
    background: inherit;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--opacity-secondary-text-color);
    transition: all 0.2s;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    span {
        width: 17px;
        height: 17px;
        margin-right: 12px;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.2s;

        svg {
            fill: var(--blue-check-color);
        }
    }

    &:hover {
        background-color: rgba(217, 235, 246, 0.5);
    }

    &.selected {
        background-color: #d9ebf6;

        span {
            opacity: 1;
        }
    }
}
