$placeholder-color: #b4b9c0;

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: $color;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
        opacity: 1;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color;
    }
    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $color;
    }

    &::placeholder {
        /* Most modern browsers support this now. */
        color: $color;
    }
}

.input-wrapper {
    display: block;
    position: relative;
    .icon {
        max-width: 20px;
        position: absolute;
        right: 10px;
        height: 48px;
        display: flex;
        align-items: center;
        top: 0;

        // иконка показать/скрыть пароль
        span {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
}

.input,
.custom-input {
    @include placeholder($placeholder-color);
    padding: 12px 29px 12px 16px;
    display: block;
    border-radius: 6px;
    border: 1px solid #dddddd;

    color: #000000;
    font-size: 0.9375rem;

    &--bg {
        width: 100%;
        max-height: 48px;
        padding: 12px 8px 12px 8px;
        border: 1px solid var(--input-border-color);
        background: var(--input-bg-color);
        font-size: 1rem;
        line-height: 24px;
        color: var(--wrapper-color-whit-black);
    }

    &--error {
        border: 1px solid var(--error);
    }
    &--fluid {
        width: 100%;
    }
    &--with-icon {
        padding-right: 32px;
    }
    input {
        border: 0 !important;
        width: 100%;
        height: 100%;
        &:focus {
            outline: 0;
        }
    }
    &:focus {
        outline: auto;
    }
}

.error-message {
    margin-top: 8px;
    margin-bottom: 12px;
    color: var(--error);
    font-size: 0.75rem;
}

.warning-message {
    @extend .error-message;
    color: #5e5a5a;
}

.readonly {
    &:focus {
        outline: none;
    }
}
