.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.title {
    margin-top: 32px;
}
