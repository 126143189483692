.checkbox-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.label {
    margin: 0 10px 0 0;
    padding: 0;
}

.checkbox {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: #fff;
    border: 2px solid #bababa;
    border-radius: 4px;
    box-sizing: border-box;

    &:hover {
        border-color: var(--main-color);
    }
}

.checkbox_checked {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: 2px solid #bababa;
    border-radius: 4px;
    box-sizing: border-box;
    background: var(--main-color) url('./checkbox.png') no-repeat center;
    background-size: 12px 10px;

    &:hover {
        border-color: #174882;
    }
}
