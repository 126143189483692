.cashbox-role {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  &__header {
    text-align: center;
    margin: 0 0 1rem;
  }
  &__check-container {
    display: flex;
    margin: 0 0 1rem;
    label {
      justify-content: space-between;
    }
  }
 &__select {
   width: 100%;
 }
  &__error {
    font-size: 12px;
    color: #da3c5c;
    margin-top: 5px;
 }
}
