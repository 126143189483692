.NewSelect {
    width: 100%;
    position: relative;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 2px 8px;
    border: 1px solid var(--input-border-color);
    border-radius: 6px;
    background: var(--input-bg-color);
    color: var(--wrapper-color-whit-black);
    cursor: pointer;
    user-select: none;

    &::before {
        content: '';
        position: absolute;
        top: 20px;
        right: 10px;
        display: block;
        width: 14px;
        height: 9px;
        background: url('./chevron-up.svg') center center / cover no-repeat;
        transform: rotate(180deg);
    }
}

.popup {
    border: 1px solid var(--input-border-color);
    box-shadow: 0 0 2px 0 var(--hover-color);
    border-radius: 6px;
}

.empty-block {
    width: 100%;
    height: 10px;
    background: var(--input-bg-color);
}

.empty-block + .body {
    padding-top: 0;
}

.body {
    width: 100%;
    padding: 8px;
    background: var(--input-bg-color);
    cursor: pointer;
    user-select: none;
    overflow-y: scroll;

    @media (min-width: 768px) {
        $color-track: #fbfbfb;
        $color-thumb: #c4c4c4;

        // Chrome, Edge, Safari, Opera
        &::-webkit-scrollbar-track {
            background: $color-track;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba($color-thumb, 0.5);
            border: 2px solid $color-track;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgba($color-thumb, 0.8);
        }

        // Firefox
        & {
            scrollbar-width: thin;
            scrollbar-color: $color-thumb $color-track;
        }
    }
}

.option {
    color: var(--wrapper-color-whit-black);
    line-height: 33px;
    white-space: nowrap;

    &:hover {
        color: var(--main-color);
    }
}

.disabled {
    cursor: default;
    opacity: 0.5;
}
