.search-field {
    width: 100%;
    background-color: #f9f9fa;
    padding: 0 13px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    border: 1px solid #f9f9fa;
    &:focus-within {
        border: 1px solid var(--main-color);
    }
    .input {
        background-color: transparent;
        width: 100%;
        color: #000;
        border: 0;
        font-size: 0.75rem;
        padding: 11px 0;
        &:focus {
            outline: 0;
        }
        &::placeholder {
            opacity: 0.5;
        }
    }
    &--fullsize {
        width: 100%;
        flex-basis: 100%;
        .input {
            padding: 15px 0;
        }
    }
    .search-icon {
        padding-right: 13px;
        position: relative;
        top: 2px;
        border: 0;
        background-color: transparent;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: 0;
        }
    }
}
