.login-form {
    .links {
        padding-top: 35px;
    }
    [class*='input-wrapper'] {
        &:first-of-type {
            margin-bottom: 10px;
        }
    }
}
