.wrapper {
    position: relative;
    .input {
        border: 1px solid #bdbdbd;
        border-radius: 6px;
        background: #fbfbfb;

        display: flex;
        justify-content: space-between;
        padding: 6px 8px;
        width: 74px;
        user-select: none;

        &:hover {
            cursor: pointer;
        }
    }

    .options {
        position: absolute;
        bottom: -10px;
        transform: translateY(100%);
        left: 0;

        border: 1px solid #bdbdbd;
        border-radius: 6px;
        background: #fbfbfb;
        width: 80px;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        gap: 4px;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s opacity;

        &.open {
            opacity: 1;

            .option {
                pointer-events: all;
            }
        }

        .option {
            padding: 2px 6px;
            pointer-events: none;
            &:hover {
                cursor: pointer;
                background: rgba(239, 239, 243, 0.4);
            }
        }
    }
}

.chevron {
    transition: 0.25s all;

    &.rotate-chevron {
        transform: rotate(180deg);
    }
}
