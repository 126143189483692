.wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 500px) {
        flex-direction: column;
    }
}

.big-column {
    flex: 1 1 66.66667%;
    padding-right: 50px;

    @media (max-width: 500px) {
        flex: 0 0 100%;
        padding-right: 0;
        margin-bottom: 40px;
    }
}
.small-column {
    flex: 1 1 33.33333%;

    @media (max-width: 500px) {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
    }
}
