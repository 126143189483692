.settlement-filter {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 30px;

    .actions-wrapper {
        display: flex;
        align-items: flex-end;
    }
}
