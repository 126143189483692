$loader-color: white;

.button {
    padding: 13px 20px;
    border-radius: 6px;

    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    border-color: rgba(0, 0, 0, 0);
    background: var(--main-color);
    transition: background-color 400ms;
    border: 0;

    color: #ffffff;
    white-space: nowrap;
    text-align: center;

    &:hover,
    &:active {
        background-color: var(--hover-color);
    }

    &:hover,
    &:active,
    &:focus {
        outline: 0;
    }

    &:hover {
        cursor: pointer;
    }
    &:disabled {
        background-color: var(--disabled-color);
    }

    &--medium {
        padding: 12px 24px;
        display: inline-block;

        border: 1px var(--main-color);
        font-weight: 600;
    }

    &--smedium {
        padding: 6px 16px;
        height: 36px;
    }

    &--xs {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        padding: 0 10px;
        border: 1px var(--main-color);
        font-weight: 600;
        // line-height: 16px;
    }

    &--tiny {
        padding: 12px 20px;
        display: inline-block;

        border: 1px var(--main-color);
        font-weight: 600;
    }
    &--outline {
        border: 1px solid #99999b;

        font-weight: 600;
        transition: border-color 800ms;
        background-color: transparent;
        color: #99999b;

        transition: border-color, color 400ms, 400ms;

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: var(--main-color);
            color: var(--main-color);
        }
        &.button--gray {
            color: #99999b;
            border-color: #99999b;
            &:hover {
                color: var(--main-color);
                border-color: var(--main-color);
            }
        }
    }

    &--clear {
        padding: 5px;
        background-color: transparent;
        border: none;

        font-weight: normal;
        color: var(--main-color);
        text-transform: none;
        letter-spacing: normal;

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    &--gray :not(.button--outline) {
        background-color: #99999b;
        color: white;
    }
    &--fluid {
        display: block;
        width: 100%;
    }
    &--disabled,
    &--disabled:hover {
        cursor: not-allowed;
    }
    .loading-icon {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        vertical-align: text-bottom;
        border: 0.18rem solid $loader-color;
        border-right-color: $loader-color;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner-border 0.75s linear infinite;
    }
}

@keyframes spinner-border {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
