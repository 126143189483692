$level-bar-colors: (
    rgb(233, 233, 233),
    rgb(255, 174, 174),
    rgb(255, 235, 197),
    rgb(204, 250, 152)
);

@function level-bar-width($level) {
    @if $level > 1 and $level < 4 {
        @return 100 / 3 * $level + px;
    }
    @return 100%;
}
.input-password {
    &__description {
        margin-left: 4px;
        padding: 0;
        padding-top: 10px;

        list-style: none;
        li {
            font-size: 0.75rem;
        }
    }
    &__level {
        max-width: 310px;
    }
    &__field {
        width: 100%;
    }
}

.levels-bar {
    height: 8px;

    transition: width 0.5s ease;
    border-radius: 0;
    // margin-bottom: 10px;
    // margin-top: 10px;
}

// в зависимости от уровня, прогресс бар окрашивается в нужный цвет
@for $i from 1 through 4 {
    .level-#{$i} + .input-password__description {
        color: #5e5a5a;
    }

    .level-#{$i} {
        transition: width 0.5s ease;
        background-color: nth($level-bar-colors, $i);
        width: level-bar-width($i);
    }
}
