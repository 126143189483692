.trigger {
    position: relative;
    width: 56px;
    height: 56px;
    padding: 8px 16px;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: var(--inactive-color);
    border-radius: 4px;
    color: #000;
    transition: background-color 600ms, box-shadow 600ms, opacity 600ms;

    &--open {
        background: var(--active-color);
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        .trigger__reset-icon {
            visibility: hidden;
            opacity: 0;
        }
    }
    &:hover,
    &:focus-within {
        cursor: pointer;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }
    &:focus {
        outline: 0;
    }
    &__count {
        width: 18px;
        height: 18px;
        position: absolute;
        top: -5px;
        right: -5px;

        background-color: var(--main-color);
        border-radius: 50%;
        color: #fff;
        font-size: 0.6875rem;
        font-weight: 700;
        transition: visibility, opacity 400ms;
        visibility: hidden;
        opacity: 0;
        &--visible {
            visibility: visible;
            opacity: 1;
        }
    }
}
.columns {
    display: flex;
    .filter-column {
        min-width: 232px;
        display: flex;
        flex-direction: column;
        &:not(:last-of-type) {
            margin-right: 13px;
        }

        &__title {
            margin-bottom: 12px;
            padding: 0 10px;
            font-size: 0.75rem;
            color: #000;
            font-weight: 700;
        }
        &__option {
            padding: 6px 10px;
            border: 0;

            text-align: left;

            font-size: 0.75rem;
            color: var(--main-color);
            background-color: transparent;
            border-radius: 6px;
            &:not(:last-of-type) {
                margin-bottom: 4px;
            }
            &--selected {
                background-color: #f9f9fa;
            }
            &:hover {
                cursor: pointer;
            }
            &:hover,
            &:focus {
                outline: 0;
            }
        }
    }
}
.popup {
    min-width: 400px;
    padding: 32px;

    visibility: hidden;
    opacity: 0;
    transition: opacity 600ms, visibility 600ms;
    background-color: var(--active-color);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    position: absolute;
    top: 65px;

    z-index: 2;
    &--open {
        visibility: visible;
        opacity: 1;
    }

    .search-field {
        margin-bottom: 8px;
    }

    .terminal {
        position: relative;
        padding: 8px;
        padding-left: 46px;
        width: 100%;

        display: flex;
        align-items: center;

        font-size: 0.75rem;
        color: #000;
        opacity: 0.9;
        &:hover {
            cursor: pointer;
        }
        &:not(:last-of-type) {
            margin-bottom: 2px;
        }

        .selected-icon {
            position: absolute;
            left: 13px;
            visibility: hidden;
        }
        &--selected {
            background-color: #f9f9fa;
            opacity: 1;
            border-radius: 6px;
            .selected-icon {
                visibility: visible;
            }
        }
    }
}

.wrapper {
    position: relative;
    max-width: 100%;
}

.actions {
    margin-top: 20px;
    margin-left: 4px;
    button {
        &:not(:last-of-type) {
            margin-right: 9px;
        }
    }
}

@media screen and (max-width: 500px) {
    .trigger {
        width: 52px;
        height: 52px;
    }
    .popup {
        max-width: 95vw;
        min-width: fit-content;
        .filter-column__option {
            margin-bottom: 20px;
        }
        .columns {
            flex-wrap: wrap;
        }
        .actions {
            button {
                width: 100%;
                &:first-of-type {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
