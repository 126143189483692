$link-background: #eaedf3;

.base-link {
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    padding-left: 50px;

    color: white;
    border-radius: 5px;

    transition: background-color 200ms;
    &:hover {
        background-color: rgba(234, 237, 243, 0.1);
        text-decoration: none;
    }
}

.backdrop {
    width: 100%;
    height: 100%;
    position: absolute;

    background-color: rgb(43, 43, 43);

    left: -10000px;
    opacity: 0;

    transition: opacity 400ms;
    cursor: pointer;

    &--open {
        left: 0;
        opacity: 0.6;
        z-index: 2;
    }
}

.mobile-navigation {
    @media screen and (max-width: 500px) {
      z-index: 2;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      height: 54px;
    }

    :global(button) {
        border: 0;
        background-color: transparent;
        outline: 0;
        cursor: pointer;

        &:active,
        &:focus {
            border: 0;
            outline: 0;
        }
    }

    .menu {
        min-height: 54px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: var(--main-color);
    }

    .menu-icons {
        display: flex;
        align-items: flex-end;

        & > :last-child {
            margin-left: 27px;
        }

        button {
            display: flex;
            align-items: flex-end;
        }
    }

    .links {
        height: 100vh;
        width: 100%;
        position: absolute;
        overflow-y: auto;

        padding-left: 25px;

        top: -1000px;
        opacity: 0;

        transition: opacity 300ms;
        z-index: 10;

        background-color: var(--main-color);

        &--open {
            top: 0;
            opacity: 1;
        }

        .logo {
            padding: 16px;
            margin-bottom: 10px;
        }

        .user-menu {
            &__user {
                max-width: 90%;
                margin: 40px 10px 20px 10px;
                padding-left: 50px;
                display: flex;
                position: relative;

                span {
                    color: #fff;
                    font-weight: 600;
                }

                div {
                    position: absolute;
                    left: 5px;
                    top: -4px;

                    circle {
                        display: none;
                    }
                }
            }
        }

        :global(.dropdown__link) {
            display: block;
            font-size: 0.875rem;
            text-align: left;
        }

        :global(.link),
        :global(.dropdown__link),
        :global(.section-link) {
            @extend .base-link;

            &:global(.active) {
                font-weight: 600;

                :global(.section-link__icon) {
                    svg {
                        path {
                            fill: #fff !important;
                        }
                    }
                }
            }
        }

        :global(.section-link__icon) {
            position: absolute;
            left: 48px;
            display: flex;
        }

        :global(.section-link) {
            svg {
                path {
                    fill: white;
                }
            }
        }

        :global(.dropdown__link),
        :global(.dropdown__button) {
            height: auto;
            max-width: 90%;

            margin: 10px;
            padding: 10px;
            padding-left: 50px;

            color: #fff;
        }

        :global(.dropdown__button) {
            @extend .base-link;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 80px;

            width: 100%;
            text-align: left;
            height: fit-content;
            position: relative;

            font-size: 0.875rem;

            &:hover {
                text-decoration: none;
            }
        }

        .close-icon {
            position: absolute;
            right: 10px;
            top: 10px;

            svg {
                line {
                    stroke: white;
                }
            }
        }
    }

    @media screen and (min-width: 500px) {
        display: none;
    }
}
