.show-more {
    margin-top: 12px;
    font-size: 14px;
    border: none;
    background-color: transparent;
    color: #002e5a;
}

.title {
    font-weight: 500;
    border-bottom: 1px solid #f0f2f2;
    padding-bottom: 6px;
    margin: 0;
}

.body {
    display: flex;
    flex-direction: column-reverse;
}
