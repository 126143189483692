.public-form {
    svg {
        path {
            fill: #002e5a;
        }
    }
    .header {
        color: #333333;
        font-size: 1.5rem;
        padding-top: 30px;
        padding-bottom: 16px;
    }
    button[type='submit'] {
        margin-top: 20px;
    }
}
