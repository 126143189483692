.dynamic-filter {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 2rem 0;
    color: var(--wrapper-color-whit-black);

    @media screen and (max-width: 1024px) {
        margin: 0;
        flex-flow: column;
        align-items: flex-start;
    }

    &__menu {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        height: 100%;
        justify-content: space-between;
        align-items: flex-end;

        @media screen and (max-width: 1400px) {
            margin-bottom: 22px;
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            flex-wrap: nowrap;

            .card:first-of-type {
                margin-bottom: 20px;
            }
        }

        .card {
            border: var(--gray-border);
        }

        &__block {
            width: 323px;
            margin-right: 3rem;

            &__row {
                display: flex;
                flex-flow: row;
                margin-top: 1rem;

                &:first-child {
                    margin-top: 0;
                }

                span {
                    width: 130px;
                    display: block;
                    line-height: 2.5;
                }
            }
        }

        // переопределяет свойство для класса из файла bootstrap.scss
        &__block > .card {
            position: static;
        }
    }

    &__submit {
        display: flex;
        height: 100%;
        margin-top: auto;
        align-items: flex-end;
        justify-content: flex-end;
    }
}
