.interval {
    display: flex;
    gap: 30px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.amount {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.button {
    min-width: 32px;
    font-size: 18px;
    text-align: center;
}

.icon {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px;
    background: none !important;
    cursor: pointer;

    i {
        font-size: 20px;
        color: #1d5d90;
    }
}

@media (max-width: 575px) {
    .interval {
        gap: 5px 15px;
    }

    .tool-tip {
        max-width: 200px;
    }
}
