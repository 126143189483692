.page {
    display: flex;
    flex-direction: column;

    .main {
        height: calc(100vh - 188px);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        background: url('./ships.jpg') fixed center no-repeat;
        background-size: cover;
        @media screen and (max-width: 1199px) and (max-height: 1000px) {
            height: auto;
            padding: 119px 0;
        }
        .content {
            max-width: 80%;
            min-width: 364px;
            background: #ffffff;
            box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            padding: 27px;
            @media screen and (max-width: 400px) {
                min-width: 300px;
                padding: 27px 20px;
            }
        }
    }

    .footer {
        padding: 20px 0 10px 0;
        background-color: var(--main-color);
        border-color: var(--main-color);

        footer {
            margin: 0 auto;
            max-width: 1250px;
            padding-left: 100px;
            padding-right: 100px;

            @media screen and (max-width: 1199px) {
                margin: 20px auto 20px auto;
            }
            @media screen and (max-width: 500px) {
                margin: 0 auto 0 auto;
            }
        }
    }
}

.wrapper {
    height: 100vh;
    overflow-y: auto;
}
