.filters-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 24px;
    color: var(--wrapper-color-whit-black);
    flex-wrap: wrap;

    @media (max-width: 1199px) {
        gap: 20px;
    }

    @media (max-width: 500px) {
        gap: 18px;
    }
}
