.reversal-page {
    .component-container:first-child {
        margin: 0 0 5rem;
    }

    .extended-operation-filter-btn {
        position: static;
        width: 250px;
        background-color: var(--bg-white);
        color: var(--wrapper-color-whit-black);
        border: var(--gray-border);
        border-radius: 9px;
        margin-top: -1rem;
        margin-left: calc(50% - 250px / 2);
        text-align: center;
        line-height: 1.8rem;
        vertical-align: middle;
        cursor: pointer;
        margin-bottom: 3rem;
        user-select: none;
    }

    .gray-bg {
        background-color: var(--light-gray);
    }

    .extended-operation-filter-btn + .extended-operation-filter {
        margin-top: -3rem;
        border-radius: 0 0 6px 6px;
    }
}
