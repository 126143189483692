.operation-details {
    &__header {
        margin-bottom: 50px;

        .header-add-info {
            & > div:first-child {
                margin-bottom: 10px;
            }

            @media (max-width: 500px) {
                margin-left: 20px;
            }
        }
    }

    &__body {
        margin-bottom: 60px;
        @media (max-width: 500px) {
            margin-bottom: 40px;
        }
    }
}

.order-history-list-row {
    height: 56px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--gray-border);
    cursor: pointer;

    &.current {
        cursor: unset;
        background-color: var(--current-row) !important;
    }

    &:hover {
        background-color: var(--light-gray);
    }

    &:first-child {
        margin-top: 24px;
        border-top: var(--gray-border);
    }

    .type-name {
        color: var(--main-color);
    }
    .operation-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div:first-child {
            margin-right: 10px;
            font-weight: 700;
        }
    }
}
