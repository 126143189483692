.title-with-selector {
    display: flex;
    gap: 36px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    font-weight: 700;

    > .name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.selected {
    background-color: #f2f2f2;
}

.disabled {
    color: #0000004d;
}
