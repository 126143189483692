.field {
    display: flex;

    width: 100%;
    min-height: 48px;
    margin-bottom: 16px;

    &:last-child {
        margin: 0;
    }

    &.align-start {
        align-items: flex-start;
    }

    &.align-center {
        align-items: center;
    }

    &.align-end {
        align-items: flex-end;
    }
}

.label {
    flex: 0 0 50%;
    padding-right: 16px;

    &.bold {
        font-weight: 700;
    }
}

.input {
    flex: 0 0 50%;
}

.input.right {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: right;
    flex-wrap: wrap;
}

.one-column {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.text-field {
    min-height: 24px;
}

.error {
    font-size: 12px;
    color: var(--error);
    margin-top: 5px;
}
